// import qs from 'qs'
import { callbackTimer } from '../../components/KLineChart/KlineChart2Tools'
import { ChainId, PageTypeParams } from '../common'
import { Time } from 'lightweight-charts'

export interface KlineDataApiDataType {
  id: number;
  open: string;
  high: string;
  low: string;
  close: string;
  volume: string;
  created_time: string;
  market_date: string;
}

const getId = (id: string) => {
  const chain_id = id.split('-')[0]
  const pair_id = id.split('-')[1]
  return {
    chain_id,
    pair_id
  }
}

export const fetchBaseUrl = process.env.REACT_APP_BASE_URL
export const fetchBaseUrl2 = process.env.REACT_APP_BASE_URL2
//  获取 K线数据
export function getKLineData(params: { id: string; type: string; size?: string }): Promise<KlineDataApiDataType[]> {
  const { chain_id, pair_id } = getId(params.id)

  return fetch(fetchBaseUrl + '/dex/marketData', {
    method: 'POST',
    body: JSON.stringify({
      // eslint-disable-next-line @typescript-eslint/camelcase
      chainId: Number(chain_id),
      pairId: Number(pair_id),
      type: Number(callbackTimer(params.type)),
      pageSize: Number(params.size!) || 600,
      client: 'h5'
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.code === 0) {
        return data.data.list
      }
      return []
    })
    .catch(error => {
      console.error('Error:', error)
    })
}

export interface ResponseIncreaseDataType {
  increase: string //涨幅 前端加%
  price: string //价格
  diff: string // 差价
}
// 获取涨幅数据
export function getIncreaseData(params: {
  token: string
  tradeToken: string
  swapVersion: string
}): Promise<ResponseIncreaseDataType> {
  return fetch(fetchBaseUrl + '/api/market/amountOfIncrease', {
    method: 'POST',
    body: JSON.stringify({
      token: params.token.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      trade_token: params.tradeToken.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      swap_version: params.swapVersion.toLowerCase()
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.code === 1) {
        return data.data
      }
      return []
    })
    .catch(error => {
      console.error('Error:', error)
    })
}
export interface ListResultType<T> {
  total: number
  page: number
  pageSize: number
  lastPage: number,
  data: T
}
export interface TradeRecordListItemType {
  buyOrderId?: number //买订单id
  sellOrderId?: number //卖订单id
  pairId?: number //交易所id
  token0DealAmount: string //成交数量
  price: string //成交价格
  transactionHash: string //hash
  dealTime: Time //成交时间
  // buy_create_time?: Time //买订单时间
  // sell_create_time?: Time //卖订单时间
  isBuy: boolean
  // eslint-disable-next-line @typescript-eslint/camelcase
  sellAddress: string
  // eslint-disable-next-line @typescript-eslint/camelcase
  buyAddress: string

  // transactionHash: string;
  // transactionBlock: number;
  // logIndex: number;
  // pairId: number;
  // chainId: number;
  // buyOrderId: number;
  // sellOrderId: number;
  // buyAddress: string;
  // sellAddress: string;
  // token0DealAmount: number;
  // token0FeeAmount: number;
  // token1DealAmount: number;
  // token1FeeAmount: number;
  // price: string;
  // isBuy: boolean;
  // dealTime: number;
}

export interface TradeRecordListParams extends PageTypeParams {
  id: string
}
export function getTradeRecordList(params: TradeRecordListParams): Promise<ListResultType<TradeRecordListItemType[]>> {
  const { chain_id, pair_id } = getId(params.id)
  return fetch(fetchBaseUrl + '/dex/latestTransaction', {
    method: 'POST',
    body: JSON.stringify({
      // eslint-disable-next-line @typescript-eslint/camelcase
      chainId: Number(chain_id),
      pairId: Number(pair_id),
      page: params.page,
      pageSize: params.size
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.code === 0) {
        return {
          ...data.data,
          data: data.data.list
        }
      }
      return []
    })
    .catch(error => {
      console.error('Error:', error)
    })
}
//  获取24
export interface CoinExType {
  chainId: number;
  pairId: number;
  token0: string;
  token1: string;
  token0Name: string;
  token1Name: string;
  token0Logo: string;
  token1Logo: string;
  info24H: Info24H;
  price: string;
  priceDecimals: number;
  change24H: string;
  // extra: Extra;
  isTrue?: boolean;
  holders: string;
  famc: string;
}
// interface Extra {
//   chainId: number;
//   chainRpc: string;
//   dexContract: string;
//   multiCallContract: string;
// }
interface Info24H {
  maxPrice: string;
  minPrice: string;
  vol: string;
  volValue: string;
}
export function getCoinExTypeData(params: { id: string }): Promise<CoinExType> {
  const { chain_id, pair_id } = getId(params.id)
  return fetch(fetchBaseUrl2 + '/dex/pairs', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },
    body: JSON.stringify({
      pairId: Number(pair_id),
      chainId: Number(chain_id)
    })
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.data.length) {
        return data.data[0]
      }
      return {}
    })
    .catch(error => {
      console.error('Error:', error)
    })
}

export function getLatestMarketOne(params: { id: string; type: string; size?: string }): Promise<KlineDataApiDataType> {
  const { chain_id, pair_id } = getId(params.id)
  return fetch(fetchBaseUrl + '/api/market/getLatestMarketPrice', {
    method: 'POST',
    body: JSON.stringify({
      // eslint-disable-next-line @typescript-eslint/camelcase
      chain_id,
      pair_id,
      type: callbackTimer(params.type)
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.code === 1) {
        return data.data
      }
      return {}
    })
    .catch(error => {
      console.error('Error:', error)
    })
  //   /api/market/latestMarket
}

export interface CoinInfoDetailType {
  chainId: number //链id
  chainName: string //链名称
  releaseNum: string //发行数量（暂时不用这个字段）
  releaseTime: string //发行时间
  name: string //币种名称
  contract: string //合约地址
  intro: string //币种简介
}

// market/coinInfo
export function getCoinInfoDetail(params: { id: string; lang?: string }): Promise<CoinInfoDetailType> {
  const { chain_id, pair_id } = getId(params.id)
  return fetch(fetchBaseUrl + '/dex/getDexPairDetail', {
    method: 'POST',
    body: JSON.stringify({
      // eslint-disable-next-line @typescript-eslint/camelcase
      pairId: Number(pair_id),
      chainId: Number(chain_id),
      lang: params.lang || 'zh-cn'
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.code === 0) {
        return data.data
      }
      return {}
    })
    .catch(error => {
      console.error('Error:', error)
    })
  //   /api/market/latestMarket
}

export interface CoinObjectInfoDetail extends CoinInfoDetailType, CoinExType { }

export function judgeTokenHasMarketData(params: {
  token: string
  tradeToken: string
  swapVersion: string
  chainId?: ChainId
}): Promise<number> {
  return fetch(fetchBaseUrl + '/api/market/isMarketData', {
    method: 'POST',
    body: JSON.stringify({
      token: params.token.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      trade_token: params.tradeToken.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      swap_version: params.swapVersion.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      chain_id: params.chainId
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.code === 1) {
        return data.data.is_market_data
      }
      return {}
    })
    .catch(error => {
      console.error('Error:', error)
    })
}

export interface LatestTradeAddressListItem {
  buy_num: number //买数量
  sell_num: number //卖数量
  date_time: string //时间
  time: number | string //时间戳
}
export function getLatestTradeAddressList(params: {
  token: string
  tradeToken: string
  swapVersion: string
}): Promise<LatestTradeAddressListItem[]> {
  return fetch(fetchBaseUrl + '/api/market/latestTradeAddress', {
    method: 'POST',
    body: JSON.stringify({
      token: params.token.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      trade_token: params.tradeToken.toLowerCase(),
      // eslint-disable-next-line @typescript-eslint/camelcase
      swap_version: params.swapVersion.toLowerCase()
      // eslint-disable-next-line @typescript-eslint/camelcase
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.code === 1) {
        return data.data
      }
      return []
    })
    .catch(error => {
      console.error('Error:', error)
    })
}

export interface CommissionedOrderItemType {
  price: string
  amount: string
  remainAmount: string
}
export interface CommissionedOrderType {
  buy: CommissionedOrderItemType[] //买数量
  sell: CommissionedOrderItemType[] //卖数量
}
export function getCommissionedOrderList(id: string, decimals: string, limit = 20): Promise<CommissionedOrderType> {

  const { chain_id, pair_id } = getId(id)
  return fetch(fetchBaseUrl2 + '/dex/depth', {
    method: 'POST',
    body: JSON.stringify({
      chainId: Number(chain_id),
      pairId: Number(pair_id),
      decimals: Number(decimals),
      maxLen: limit
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.data && Object.keys(data.data).length) {
        return {
          buy: data.data.buyDepth || [],
          sell: data.data.sellDepth || []
        } as any
      }
      return {}
    })
    .catch(error => {
      console.error('Error:', error)
    })
}

export function getCommissionedOrderList2(id: string, decimals: string, limit = 20): Promise<CommissionedOrderType> {
  const { chain_id, pair_id } = getId(id)

  return fetch(fetchBaseUrl2 + '/dex/depth', {
    method: 'POST',
    body: JSON.stringify({
      chainId: Number(chain_id),
      pairId: Number(pair_id),
      decimals: Number(decimals),
      maxLen: limit
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.data && Object.keys(data.data).length) {
        return {
          buy: data.data.buyDepth || [],
          sell: data.data.sellDepth || []
        } as any
      }
      return {}
    })
    .catch(error => {
      console.error('Error:', error)
    })
}

export interface MarketEarningExponentType {
  day_increase: string //一天的
  week_increase: string //一个星期的
  month_increase: string //一月的
  three_month_increase: string //三个月的
  year_increase: string //一年的
}
export function getMarketEarningExponent(id: string): Promise<MarketEarningExponentType> {
  const { chain_id, pair_id } = getId(id)
  return fetch(fetchBaseUrl + '/dex/moreIncrease', {
    method: 'POST',
    body: JSON.stringify({
      // eslint-disable-next-line @typescript-eslint/camelcase
      chainId: Number(chain_id),
      pairId: Number(pair_id)
      // token: params.token.toLowerCase(),
      //
      // trade_token: params.tradeToken.toLowerCase(),
      // // eslint-disable-next-line @typescript-eslint/camelcase
      // swap_version: params.swapVersion.toLowerCase()
      // eslint-disable-next-line @typescript-eslint/camelcase
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      return response.json()
    })
    .then(data => {
      if (data.code === 0) {
        return data.data
      }
      return {}
    })
    .catch(error => {
      console.error('Error:', error)
    })
}
